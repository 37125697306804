/**
 *标准时间格式化
 * @param {*} date ：Fri Mar 08 2019 15:47:52 GMT+0800 (中国标准时间)
 */
import { inBrowser } from 'vue-router/src/util/dom'

export const dateformat = (time) => {
  const date = new Date(time)
  console.log('date:', date)
  const y = date.getFullYear()
  const m = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
  const d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + s
}

/**
 * 获取url get参数方法
 * @param {*} item 为get参数的键
 */
export const getGetData = function (item) {
  const search = location.search
  let strArr = []
  const obj = {}
  if (search.indexOf('?') !== -1) {
    strArr = search.slice(search.indexOf('?') + 1).split('&')
    strArr.forEach((v, i) => {
      const key = v.split('=')[0]
      const val = v.split('=')[1]
      obj[key] = val
    })
    for (const k in obj) {
      if (item === k) {
        return obj[k]
      }
    }
  }
}
/**
 *  复制文字到剪切板
 *  @params {*} text 为需要复制的文字内容
 */
export const copyToClipboard = text => {
  const textArea = document.createElement('textarea')
  textArea.style.position = 'fixed'
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.width = '2em'
  textArea.style.height = '2em'
  textArea.style.padding = '0'
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'
  textArea.style.background = 'transparent'
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.select()

  let successful
  try {
    successful = document.execCommand('copy')
  } catch (err) {
    console.log('复制到剪切板失败')
  }

  document.body.removeChild(textArea)

  return successful
}

/**
 * 设备相关检查
 * */
export const checkDevice = {
  inBrowser: typeof window !== 'undefined',
  UA: inBrowser && window.navigator.userAgent.toLowerCase()
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass (ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass (ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass (ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}
