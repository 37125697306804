<template>
  <div>
    <table-comp
    :table-opts="tableOpts"
    :handle-opts="handleOpts"
    :data="tableData"
    :page="currentPage"
    :total="totalPage"
    :page-size="pageSize"
    :open-handle="true"
    :show-number="true"
    @other_1="handleCheck"
    @handleSizeChange="sizeChange"
    @handleCurrentChange="pageChange"
    ></table-comp>
    <el-dialog
    class="dialogClass"
    title="审核并完善患者信息"
    :visible.sync="dialogVisible"
    width="800px"
    height="600px"
    @close="doClosed"
    :before-close="handleClose">
      <div class="pendingtask-container">
        <!-- <el-image
          style="width: 450px; height: 600px;"
          :src="diagnosisAttachmentUrl"
          :preview-src-list="diagnosisAttachmentList">
          <div slot="error" class= "el-image__error">
            患者暂未上传诊断证明书
          </div>
        </el-image> -->
        <div style="height: 600px; width: 800px;">
          <el-scrollbar>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="130px"
              class="demo-ruleForm">
              <div>
                <el-form-item label="患者姓名" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    minlength="2"
                    maxlength="20">
                  </el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-select v-model="ruleForm.gender" placeholder="请选择性别">
                      <el-option
                        v-for="item in regionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="病案号" prop="recordNo">
                    <el-input
                    v-model="ruleForm.recordNo"
                    minlength="6"
                    maxlength="7"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="文化程度" prop="educationBackground">
                    <el-select v-model="ruleForm.educationBackground">
                    <el-option label="请选择" value="0"></el-option>
                    <el-option label="初中及以下" value="1"></el-option>
                    <el-option label="高中" value="2"></el-option>
                    <el-option label="大学" value="3"></el-option>
                    <el-option label="研究生及以上" value="4"></el-option>
                    </el-select>
                </el-form-item> -->
<!--                <el-form-item label="常住地" prop="">-->
<!--                    <el-cascader-->
<!--                      v-model="ruleForm.formAddress"-->
<!--                      :placeholder="defaultAddress"-->
<!--                      :options="addressOptions"-->
<!--                      style="display: block; width: 288px;"-->
<!--                      filterable-->
<!--                    />-->
<!--                </el-form-item>-->
                <el-form-item label="身份证号" prop="certificateNo">
                    <el-input
                      :disabled="true"
                    v-model="ruleForm.certificateNo"
                    autocomplete="off"
                    maxlength="18"
                    ></el-input>
                </el-form-item>
                <el-form-item class="hasDiseases" label="基础疾病">
                  <el-radio-group v-model="hasUnderlyingDiseases" @change="changeHasDiseases">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                  </el-radio-group>
                  <div class="diseases-box" v-if="hasUnderlyingDiseases === 1">
                    <el-checkbox-group
                      v-model="underlyingDiseases"
                      @change="handleChangeUdrDis">
                      <el-checkbox
                        class="el-checkbox-width"
                        v-for="item in ruleForm.underlyingDiseaseSelect" :label="item.code"
                        :key="item.code">
                        {{item.text}}
                        <el-input
                          class="input-otherdiseases"
                          v-show="item.type === 2 && visableOtherDisInput"
                          v-model="ruleForm.otherDiseases"
                          minlength="2"
                          maxlength="20"
                        ></el-input>
                        <br>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="CIED植入信息" prop="">
                  <div class="content-form custom-style">
                    <el-form :model="implantInfo" :rules="implantInfoRules" ref="implantInfoRuleForm" label-width="80px">
                      <el-row>
                          <el-form-item label="植入日期" prop="implantDate">
                            <el-date-picker
                              v-model="implantInfo.implantDate"
                              class="fix-form__width"
                              type="date"
                              format="yyyy-MM-dd"
                              :picker-options="pickerOptions"
                              value-format="timestamp"
                              placeholder="选择日期">
                            </el-date-picker>
                          </el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item label="植入医院">
                            <el-input v-model="implantInfo.implantHospital" class="fix-form__width"></el-input>
                          </el-form-item>
                        </el-row>
                      <el-row>
                        <el-form-item label="术者" prop="implantDoctor">
                          <el-input v-model="implantInfo.implantDoctor" minlength="2" maxlength="20" class="fix-form__width"></el-input>
                        </el-form-item>
                      </el-row>
                    </el-form>
                  </div>
                </el-form-item>
                <el-form-item label="CIED植入部位" prop="">
                  <el-radio-group class="radio-group-one-level" v-model="implantPosition">
                    <el-radio :label="1">左侧</el-radio>
                    <el-radio :label="2">右侧</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="适应证" prop="">
                  <el-checkbox-group v-model="indications.indicationsSymptom" @change="changeAdaptation">
                    <el-checkbox class="el-check-pinpai" :label="0">
                      心动过缓
                    </el-checkbox>
                    <el-checkbox-group v-if="indications.indicationsSymptom.indexOf(0) !== -1" v-model="indications.bradycardiaRadio" class="adaptation_content--1">
                      <el-checkbox :label="0">病态窦房结综合征</el-checkbox>
                      <el-checkbox :label="1">房室传导阻滞</el-checkbox>
                      <el-checkbox :label="2">房颤伴慢室率</el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox class="el-check-pinpai" :label="1">
                      心力衰竭
                    </el-checkbox>
                    <br>
                    <el-checkbox class="el-check-pinpai" :label="2">
                      室性心律失常
                    </el-checkbox>
                    <el-radio-group @change="changeIndicationsSymptop" v-if="indications.indicationsSymptom.indexOf(2) !== -1" v-model="indications.arrhythmiaRadio" class="adaptation_content--1">
                      <el-radio :label="0">室性心动过速</el-radio>
                      <el-radio :label="1">心室颤动</el-radio>
                      <el-radio :label="2">室性期前收缩</el-radio>
                    </el-radio-group>
                    <el-checkbox :label="3">
                      其他
                    </el-checkbox>
                    <el-input
                      :disabled="indications.indicationsSymptom.indexOf(3) === -1"
                      v-model="indications.otherIndications"
                      placeholder="请输入内容"
                      style="display: block; width: 228px;"
                      minlength="2"
                      maxlength="20"
                      :onkeyup="
                        (function () {
                          indications.otherIndications=indications.otherIndications.replace(/[^\u4e00-\u9fa5a-zA-Z0-9·]/ig,'')
                        })()">
                    </el-input>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="CIED类型" prop="">
                  <el-radio-group v-model="ciedType.ciedTypeRadio" @change="changeCiedType">
                    <el-radio :label="0">单腔起搏器</el-radio>
                    <el-radio :label="1">双腔起搏器</el-radio>
                    <el-radio :label="2">单腔ICD</el-radio>
                    <el-radio :label="3">双腔ICD</el-radio>
                    <el-radio :label="4">CRT-P</el-radio>
                    <el-radio :label="5">CRT-D</el-radio>
                    <el-radio :label="6">其他</el-radio>
                    <el-input
                    :disabled="ciedType.ciedTypeRadio !== 6"
                    v-model="ciedType.otherCiedType"
                    placeholder="请输入内容"
                    style="display: inline-block; width: 228px"
                    :onkeyup="
                    (function () {
                      ciedType.otherCiedType=ciedType.otherCiedType.replace(rules,'')
                    })()"
                    ></el-input>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="CIED品牌" prop="">
                  <el-radio-group class="ciedBrand-radio-group" v-model="ciedBrand.key" @change="changeCiedBrand">
                    <el-radio :label="0">美敦力
                      <el-select :disabled="ciedBrand.key !== 0" filterable class="el-checkbox-input" allow-create v-model="ciedBrand.model" placeholder="请输入">
                        <el-option
                          v-for="i in ciedBrandSelect[0].children"
                          :key="i.code"
                          :label="i.title"
                          :value="i.code">
                        </el-option>
                      </el-select>
                    </el-radio>
                    <br>
                    <el-radio :label="1">雅培&nbsp;&nbsp;&nbsp;&nbsp;
                      <el-input
                        v-model="ciedBrand.yapeitext"
                        :disabled="ciedBrand.key !== 1"
                        class="el-checkbox-input"
                        minlength="2"
                        maxlength="20">
                      </el-input>
                    </el-radio>
                    <br>
                    <el-radio :label="2">百力多
                      <el-input
                        v-model="ciedBrand.bailiduotext"
                        :disabled="ciedBrand.key !== 2"
                        class="el-checkbox-input"
                        minlength="2"
                        maxlength="20">
                      </el-input>
                    </el-radio>
                    <br>
                    <el-radio :label="3">波科&nbsp;&nbsp;&nbsp;&nbsp;
                      <el-input
                        v-model="ciedBrand.boketext"
                        :disabled="ciedBrand.key !== 3"
                        class="el-checkbox-input"
                        minlength="2"
                        maxlength="20">
                      </el-input>
                    </el-radio>
                    <br>
                    <el-radio :label="4">其他&nbsp;&nbsp;&nbsp;&nbsp;
                      <el-input
                        v-model="ciedBrand.qitatext"
                        :disabled="ciedBrand.key !== 4"
                        class="el-checkbox-input"
                        minlength="2"
                        maxlength="20"
                        :onkeyup="
                        (function () {
                          ciedBrand.qitatext=ciedBrand.qitatext.replace(/[^\u4e00-\u9fa5a-zA-Z0-9·]/ig,'')
                        })()">
                      </el-input>
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="导线" prop="wireParam">
                  <el-form :model="wireParam">
                    <el-form-item>
                      <el-checkbox v-model="wireParam.checkedAtrial" class="el-check-pinpai" label="心房导线" @change="changeLead"></el-checkbox>
                      <div class="lead-atrial" v-if="wireParam.checkedAtrial">
                        <div class="item-title">
                          植入部位:
                        </div>
                        <el-radio-group v-model="wireParam.atrialImplantPosition">
                          <el-radio :label="0">右心耳</el-radio>
                          <el-radio :label="1">右心房侧壁</el-radio>
                        </el-radio-group>
                        <br>
                        <div class="item-title">导线型号:</div>
                        <el-input size="small" placeholder="请输入导线型号" v-model="wireParam.atrialWireModel"></el-input>
                        <br>
                        <div class="item-title">
                          起搏阈值:
                        </div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                          </el-col>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdMs"></el-input>&nbsp;ms
                          </el-col>
                        </el-row>
                        <div class="item-title">
                          P波高度:
                        </div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPWaveAltitude"></el-input>&nbsp;mV&nbsp;
                          </el-col>
                        </el-row>
                        <div class="item-title">
                          阻抗:
                        </div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      </div>
                      <el-checkbox v-model="wireParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
                      <div class="lead-right" v-if="wireParam.checkedRightVentricular">
                      <div class="item-title">植入部位:</div>
                        <el-radio-group v-model="wireParam.rightImplantPosition">
                          <el-radio :label="0">右室心尖部</el-radio>
                          <el-radio :label="1">右室间隔部</el-radio>
                          <el-radio :label="2">希氏束区域</el-radio>
                          <el-radio :label="3">左束支区域</el-radio>
                        </el-radio-group>
                      <div class="item-title">导线型号:</div>
                      <el-input size="small" placeholder="请输入导线型号" v-model="wireParam.rightWireModel"></el-input>
                      <div class="item-title">起搏阈值:</div>
                      <el-row>
                        <el-col :span="12">
                          <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                        </el-col>
                        <el-col :span="12">
                          <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                        </el-col>
                      </el-row>
                      <div class="item-title">R波高度:</div>
                      <el-row>
                        <el-col :span="12">
                          <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightRWaveAltitude"></el-input>&nbsp;mV&nbsp;
                        </el-col>
                      </el-row>
                      <div class="item-title">阻抗:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      <div class="item-title">高压除颤阻抗:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightHighVoltageImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      </div>
                      <el-checkbox v-model="wireParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
                      <div class="lead-left" v-if="wireParam.checkedLeftVentricular">
                        <div class="item-title">植入部位:</div>
                        <el-radio-group v-model="wireParam.leftImplantPosition" @change="changeLeadSite">
                          <el-radio :label="0">侧后静脉</el-radio>
                          <el-radio :label="1">侧静脉</el-radio>
                          <el-radio :label="2">其他</el-radio>
                          <el-input
                            class="el-checkbox-input-left"
                            size="mini"
                            placeholder="请输入内容"
                            v-show="wireParam.leftImplantPosition === 2"
                            v-model="wireParam.leftOtherPosition"
                            minlength="2"
                            maxlength="20">
                          </el-input>
                        </el-radio-group>
                        <div class="item-title">导线型号:</div>
                        <el-input size="small" placeholder="请输入导线型号" v-model="wireParam.leftWireModel"></el-input>
                        <div class="item-title">起搏阈值:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                          </el-col>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                          </el-col>
                        </el-row>
                        <div class="item-title">R波高度:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftRWaveAltitude"></el-input>&nbsp;mV&nbsp;
                          </el-col>
                        </el-row>
                        <div class="item-title">阻抗:</div>
                        <el-row>
                          <el-col :span="12">
                            <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftImpedance"></el-input>&nbsp;Ω&nbsp;
                          </el-col>
                        </el-row>
                      </div>
                    </el-form-item>
                  </el-form>
                </el-form-item>
                <el-form-item label="起搏器参数设置" prop="">
<!--                    <el-checkbox v-model="pacemakerQuery.checkedPacemakerModel" style="display: block" label="起搏模式" @change="changeQuery"></el-checkbox>-->
                    <div>起搏模式</div>
                    <el-radio-group v-model="pacemakerQuery.pacemakerModel" class="subPart adaptation_content--1">
                      <el-radio :label="1">AAI(R)</el-radio>
                      <el-radio :label="2">VVI(R)</el-radio>
                      <el-radio :label="3">DDD(R)</el-radio>
                    </el-radio-group>
<!--                    <el-checkbox v-model="pacemakerQuery.checkedBareLineHeartRate" label="基线频率" style="margin-right: 20px" @change="changeQuery"></el-checkbox>-->
                    <div>基线频率</div>
                    <div>
                      <el-input
                      size="small"
                      maxlength="2"
                      v-model="pacemakerQuery.bareLineHeartRate"
                      style="width: 288px;"
                      placeholder="请输入内容"
                      :onkeyup="
                        (function () {
                          pacemakerQuery.bareLineHeartRate=pacemakerQuery.bareLineHeartRate.replace(/[^\d]/g,'')
                        })()"
                      ></el-input>&nbsp;bpm
                    </div>
                    <span >
                    </span>
                </el-form-item>
                <el-form-item label="围术期不良事件" prop="">
                  <el-radio-group class="radio-group-one-level" v-model="adverseEvent.hasEvent" @change="changeEvent">
                    <el-radio :label="0">无</el-radio>
                    <br>
                    <el-radio :label="1">有</el-radio>
                </el-radio-group>
              <el-form-item v-if="adverseEvent.hasEvent === 1">
                <el-checkbox-group v-model="adverseEvent.events" class="adaptation_content--1 events" @change="changeEventCheckBox">
                  <el-checkbox :label="1" name="type">气胸</el-checkbox>
                  <el-checkbox :label="2" name="type">血胸</el-checkbox>
                  <el-checkbox :label="3" name="type">血气胸</el-checkbox>
                  <el-checkbox :label="4" name="type">导线穿孔</el-checkbox>
                  <el-checkbox :label="5" name="type">导线脱落</el-checkbox>
                  <el-checkbox :label="6" name="type">感染</el-checkbox>
                  <el-checkbox :label="7" name="type">导线重置</el-checkbox>
                  <el-checkbox :label="8" name="type">血肿</el-checkbox>
                  <el-checkbox :label="9" name="type">其他</el-checkbox>
                  <el-input v-if="adverseEvent.events.indexOf(9) !== -1" size="small" v-model="adverseEvent.otherEvent" style="width: 150px;" placeholder=""></el-input>
                </el-checkbox-group>
              </el-form-item>
                </el-form-item>
              </div>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="handleNext(0)">驳回</el-button>
        <el-button type="primary" :disabled="disableCheckBtn" @click="handleNext(1)">完成审核</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="驳回原因"
      :visible.sync="rejectionReasonVisible"
      width="50%">
      <el-input
        type="textarea"
        placeholder="请输入驳回原因"
        v-model="rejectCause"
        maxlength="100"
        show-word-limit
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancelChangeRejectCause">取 消</el-button>
        <el-button type="primary" @click="onSaveChangeRejectCause">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import options from '@/utils/region'
import { getAwaitFollowList, confirmPatientReview, rejectPatientReview } from '@/service/module/task'
import {
  getBaselineInfo,
  getDictionary
} from '@/service/module/followup'

export default {
  components: {
    TableComp
  },
  data () {
    // var checkCertificateNo = (rule, value, callback) => {
    //   var reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/
    //   if (!value) {
    //     callback()
    //   }
    //   if (reg.test(value) === false) {
    //     callback(new Error('请输入正确的身份证号码'))
    //     return false
    //   } else {
    //     callback()
    //     return true
    //   }
    // }
    return {
      handleOpts: { // 操作列表设置
        other_1: {
          type: 'text',
          text: '审核患者信息'
        }
      },
      tableOpts: [ // 列表表头设置
        {
          label: '患者姓名',
          prop: 'name',
          width: 200,
          render: (h, params) => {
            const name = params.row.name
            if (!name) {
              return h('span', {}, '-')
            } else {
              return h('span', {}, name)
            }
          }
        },
        {
          label: '性别',
          prop: 'gender',
          width: 200,
          render: (h, params) => {
            const gender = params.row.gender
            if (!gender) {
              return h('span', {}, '-')
            }
            let genderText = ''
            switch (gender) {
              case '2' : genderText = '女'
                break
              case '1' : genderText = '男'
                break
            }
            return h('span', {}, genderText)
          }
        },
        {
          label: '病案号',
          prop: 'medicalRecordNumber',
          width: 200
        },
        {
          label: '注册时间',
          prop: 'createAt',
          width: 300,
          render: (h, params) => {
            if (!params.row.createAt) {
              return h('span', {}, '-')
            }
            var moment = require('moment')
            const createAt = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')
            return h('span', {}, createAt)
          }
        }
      ],

      // 1、植入信息
      implantInfo: {
        implantDate: '',
        implantDoctor: '',
        implantHospital: ''
      },
      // 2、植入部位
      implantPosition: -1,
      // 3、适应证
      indications: {
        indicationsSymptom: [],
        bradycardiaRadio: [],
        otherIndications: '',
        arrhythmiaRadio: null
      },
      // 是否有基础疾病
      hasUnderlyingDiseases: -1,
      // 4、类型
      ciedType: {
        ciedTypeRadio: -1,
        otherCiedType: ''
      },
      // 5、品牌
      ciedBrand: {
        yapeitext: '',
        bailiduotext: '',
        boketext: '',
        qitatext: '',
        model: null,
        key: null
      },
      ciedBrandSelect: [ // 美敦力设备列表
        { children: [] }
      ],
      // 6、导线
      wireParam: {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        // 心房导线参数
        atrialImplantPosition: -1,
        atrialWireModel: '',
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPWaveAltitude: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightImplantPosition: -1,
        rightWireModel: '',
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightRWaveAltitude: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftOtherPosition: '',
        leftImplantPosition: -1,
        leftWireModel: '',
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftRWaveAltitude: '',
        leftImpedance: ''
      },
      // 7、起搏器参数
      pacemakerQuery: {
        checkedPacemakerModel: false,
        bareLineHeartRate: '',
        checkedBareLineHeartRate: false,
        pacemakerModel: 0
      },
      // 8、不良事件
      adverseEvent: {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      },

      ruleForm: { // 患者信息表单信息
      },
      rejectCause: '', // 驳回原因
      implantInfoRuleForm: {}, // CIED植入信息表单
      rules: { // 表单校验
        username: [
          { required: true, message: '请输入患者姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        medicalRecordNumber: [
          { required: true, message: '请输入病案号', trigger: 'blur' },
          { min: 6, max: 7, message: '长度在 6-7 个字符', trigger: 'blur' },
          { pattern: /^\d+$/, message: '请输入数字' }
        ],
        certificateNo: [
          { required: true, trigger: 'blur' }
        ]
      },
      implantInfoRules: { // 植入信息表单校验
        implantDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        implantDoctor: [
          { required: true, message: '请输入术者姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ]
      },
      addressOptions: options, // 居住地地址列表

      dialogVisible: false, // 患者信息弹窗是否显示
      visableOtherDisInput: false, // 基础疾病-选中其他-出现输入框
      visableadcied: false,

      diagnosisAttachmentList: [], // 诊断书图片地址列表
      // diagnosisAttachmentUrl: '', // 诊断书列表第一张图片地址
      tableData: [], // 待处理任务列表数据
      underlyingDiseases: [], // 基础疾病列表

      defaultAddress: '', // 常住地

      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数
      regionList: [ // 性别列表
        {
          value: '1',
          label: '男'
        }, {
          value: '2',
          label: '女'
        }
      ],
      rejectionReasonVisible: false, // 驳回原因弹窗是否显示,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      disableCheckBtn: false,
      patientId: null // 患者id
    }
  },
  mounted () {
    this.getDataList(1, this.pageSize)
  },
  methods: {
    async getDataList (pageNum, pageSize) {
      try {
        const res = await getAwaitFollowList({
          pageNum: pageNum,
          pageSize: pageSize
        })
        if (res) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
          this.currentPage = res.data.currentPage
          this.pageSize = res.data.pageSize
        }
      } catch (e) {
        console.log('awaitList-err:', e)
      }
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    doClosed () {
      this.$refs.ruleForm.clearValidate()
      this.implantInfo = {
        implantDate: '',
        implantDoctor: '',
        implantHospital: ''
      }
      this.hasUnderlyingDiseases = -1
      this.implantPosition = -1
      this.indications = {
        indicationsSymptom: [],
        bradycardiaRadio: [],
        otherIndications: '',
        arrhythmiaRadio: null
      }
      this.ciedType = {
        ciedTypeRadio: -1,
        otherCiedType: ''
      }
      this.ciedBrand = {
        yapeitext: '',
        bailiduotext: '',
        boketext: '',
        qitatext: '',
        model: null,
        key: null
      }
      this.wireParam = {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        // 心房导线参数
        atrialImplantPosition: -1,
        atrialWireModel: '',
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPWaveAltitude: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightImplantPosition: -1,
        rightWireModel: '',
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightRWaveAltitude: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftOtherPosition: '',
        leftImplantPosition: -1,
        leftWireModel: '',
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftRWaveAltitude: '',
        leftImpedance: ''
      }
      this.pacemakerQuery = {
        checkedPacemakerModel: false,
        bareLineHeartRate: '',
        checkedBareLineHeartRate: false,
        pacemakerModel: 0
      }
      this.adverseEvent = {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      }
      this.$refs.implantInfoRuleForm.clearValidate()
    },
    async handleNext (type) {
      Object.assign(this.ruleForm, this.implantInfoRuleForm)

      this.ruleForm.implantDate = this.implantInfo.implantDate
      this.ruleForm.operator = this.implantInfo.implantDoctor
      this.ruleForm.hospitalName = this.implantInfo.implantHospital

      this.ruleForm.ciedBrand = this.ciedBrand

      this.ruleForm.ciedImplantSite = this.implantPosition
      this.ruleForm.adaptationDisease = this.indications
      this.ruleForm.ciedKind = this.ciedType
      this.ruleForm.leadParam = this.wireParam
      this.ruleForm.pacemakerParam = this.pacemakerQuery
      this.ruleForm.adverseEvent = this.adverseEvent

      if (type === 0) {
        this.rejectCause = ''
        this.ruleForm.type = type
        this.rejectionReasonVisible = true
      } else {
        this.$refs.ruleForm.validate(async (valid) => {
          this.$refs.implantInfoRuleForm.validate(async (v) => {
            if (valid && v) {
              if (valid & v) {
                if (this.visableOtherDisInput && this.ruleForm.otherDiseases === undefined) {
                  this.$message.error('请输入其他疾病！')
                  return false
                }
                const ruleForm = this.ruleForm
                ruleForm.type = type
                const formAddress = ruleForm.formAddress
                if (formAddress && formAddress.length > 0) {
                  ruleForm.province = formAddress[0]
                  ruleForm.city = formAddress[1]
                  ruleForm.district = formAddress[2]
                }
                this.ruleForm.underlyingDiseases = this.underlyingDiseases
                this.confirmPatientReview(1)
              } else {
                console.log('error submit!!')
                return false
              }
            } else {
              console.log('error submit!!')
              return false
            }
          })
        })
      }
    },
    onSaveChangeRejectCause () {
      if (!this.rejectCause) {
        this.$message.error('请输入驳回原因')
        return
      }
      this.confirmPatientReview(0)
    },
    onCancelChangeRejectCause () {
      this.rejectionReasonVisible = false
      this.rejectCause = ''
    },
    async handleCheck (data) {
      this.ruleForm = {}
      this.patientId = data.id
      this.underlyingDiseases = []
      this.visableOtherDisInput = false

      try {
        const res = await getBaselineInfo(data.id)
        const resDictionary = await getDictionary('underlyingDisease')
        const resCiedBrand = await getDictionary('ciedBrand')
        this.ruleForm = res.data
        this.ciedBrandSelect = resCiedBrand.data
        this.ruleForm.underlyingDiseaseSelect = resDictionary.data
        if (res.data.underlyingDiseases.length > 0) {
          this.hasUnderlyingDiseases = 1
          this.underlyingDiseases = res.data.underlyingDiseases
          this.ruleForm.otherDiseases = res.data.otherDiseases
          for (let i = 0; i < this.underlyingDiseases.length; i++) {
            this.underlyingDiseases[i] = +this.underlyingDiseases[i]
          }
          if (res.data.underlyingDiseases.indexOf(4) && res.data.otherDiseases) {
            this.visableOtherDisInput = true
          }
        }

        if (!res.data.implantDate) {
          this.ruleForm.implantDate = ''
        }
        if (!res.data.otherDiseases) {
          this.$set(this.ruleForm, 'otherDiseases', '')
        }
        console.log(this.ruleForm)
        if (res.data) {
          this.dialogVisible = true
        }
      } catch (e) {
        console.log('getAwaitFollowUserInfo-err:', e)
      }
    },
    sizeChange (val) {
      this.pageSize = val
      this.getDataList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getDataList(val, this.pageSize)
    },
    handleChangeUdrDis (list) {
      console.log(list)
      this.visableOtherDisInput = false
      if (list.indexOf(4) !== -1) {
        this.visableOtherDisInput = true
      } else {
        this.visableOtherDisInput = false
        this.ruleForm.otherDiseases = ''
      }
      // list.forEach(i => {
      //   if (i === '其他') {
      //
      //     this.ruleForm.otherDiseases = ''
      //   }
      // })
    },
    changeHasDiseases (val) {
      if (val !== 1) {
        this.underlyingDiseases = []
        this.visableOtherDisInput = false
        this.ruleForm.otherDiseases = ''
        console.log(this.ruleForm)
      }
    },
    changeCiedBrand (val) {
      this.ciedBrand.key = val
      switch (val) {
        case 0:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          break
        case 1:
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 2:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 3:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 4:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.model = null
          break
      }
    },
    async confirmPatientReview (type) {
      try {
        if (type === 1) {
          // 审核通过
          this.disableCheckBtn = true
          this.ruleForm.patientId = this.patientId
          const res = await confirmPatientReview(this.ruleForm)
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.disableCheckBtn = false
            this.dialogVisible = false
            this.getDataList(1, this.pageSize)
          } else {
            this.$message.error(res.message)
            this.disableCheckBtn = false
          }
        } else {
          // 审核驳回
          const res = await rejectPatientReview({
            patientId: this.patientId,
            rejectCause: this.rejectCause
          })
          if (res.code === 200) {
            this.$message.success('已驳回')
            this.disableCheckBtn = false
            this.rejectionReasonVisible = false
            this.dialogVisible = false
            this.getDataList(1, this.pageSize)
          }
        }
      } catch (e) {
        this.$message.error('保存失败')
        this.disableCheckBtn = false
        console.log('confirmPatientReview-err:', e)
      }
    },
    changeCiedType (val) {
      if (val !== 6) {
        this.ciedType.otherCiedType = ''
      }
    },
    changeQuery () {
      if (!this.pacemakerQuery.checkedPacemakerModel) {
        this.pacemakerQuery.model = 0
        this.pacemakerQuery.pacemakerModel = 0
      }
      if (!this.pacemakerQuery.checkedBareLineHeartRate) {
        this.pacemakerQuery.bareLineHeartRate = ''
      }
    },
    changeAdaptation (val) {
      if (val.indexOf(0) === -1) {
        this.indications.bradycardiaRadio = []
      }
      if (val.indexOf(3) === -1) {
        this.indications.otherIndications = ''
      }
      if (val.indexOf(2) === -1) {
        this.indications.arrhythmiaRadio = null
      }
    },
    changeIndicationsSymptop () {
      this.$forceUpdate()
    },
    changeEvent () {
      if (this.adverseEvent.hasEvent !== 1) {
        this.adverseEvent.events = []
        this.adverseEvent.otherEvent = ''
      }
    },
    changeEventCheckBox (val) {
      if (val.indexOf(9) === -1) {
        this.adverseEvent.otherEvent = ''
      }
    },
    changeLead () {
      if (!this.wireParam.checkedAtrial) {
        this.wireParam.atrialImplantPosition = -1
        this.wireParam.atrialWireModel = ''
        this.wireParam.atrialPacingThresholdV = ''
        this.wireParam.atrialPacingThresholdMs = ''
        this.wireParam.atrialPWaveAltitude = ''
        this.wireParam.atrialImpedance = ''
      }
      if (!this.wireParam.checkedRightVentricular) {
        this.wireParam.rightImplantPosition = -1
        this.wireParam.rightWireModel = ''
        this.wireParam.rightPacingThresholdV = ''
        this.wireParam.rightPacingThresholdMs = ''
        this.wireParam.rightRWaveAltitude = ''
        this.wireParam.rightHighVoltageImpedance = ''
        this.wireParam.rightImpedance = ''
      }
      if (!this.wireParam.checkedLeftVentricular) {
        this.wireParam.leftOtherPosition = ''
        this.wireParam.leftImplantPosition = -1
        this.wireParam.leftWireModel = ''
        this.wireParam.leftPacingThresholdV = ''
        this.wireParam.leftPacingThresholdMs = ''
        this.wireParam.leftRWaveAltitude = ''
        this.wireParam.leftImpedance = ''
      }
    },
    changeLeadSite (val) {
      if (val !== 2) {
        this.wireParam.leftOtherPosition = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.patient-info {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
}
.info-item {
  display: flex;
  flex-grow: 1;

}
.el-input,.el-select {
    width: 288px
}
.patient-info-title {
  font-size: 24px;
  font-weight: 600;
  text-align:center;
  margin-bottom: 20px;
}
.dialog-footer {
  margin: 60px 0 0 128px;
}
.pendingtask-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  font-weight: 600;
}
::v-deep .el-cascader {
  width: 100%
}
.input-otherdiseases {
  width: 288px
}
.el-checkbox-width {
  width: 150px;
  height: 25px;
}
::v-deep.dialogClass {
  margin-top: -105px;
}
::v-deep.dialogClass .el-dialog__body {
  padding-top: 0px;
  padding-bottom: 0px;
}

::v-deep.dialogClass .el-dialog__header {
  padding: 10px 20px;
}

.dialogClass .el-dialog__title {
  padding: 20px 0;
}
.dialogClass .el-dialog__footer {
  padding: 10px 20px;
}
::v-deep .el-scrollbar .el-scrollbar__wrap{
  padding-bottom: 13px;
  max-height: 600px;
}
.el-checkbox-input {
  width: 228px;
}
.el-check-pinpai {
  width: 100%;
}
::v-deep .el-checkbox:last-of-type {
  margin-right: 30px;
}
.radio-group-two-level {
  width: 300px;
  margin-left: 20px;
}
.radio-group-one-level {
  width: 300px;
}
::v-deep .el-radio {
  margin-bottom: 10px;
  line-height: 40px;
}
.editor {
    text-align: center;
    display: inline-block;
    width: 90px;
    line-height: 1;
    border-bottom: 1px solid #000;
}
.ciedBrandLabel {
  width:50px;
  display: -moz-inline-box;
  display: inline-block;
}
.el-checkbox-input-left {
  width: 180px;
  height: 10px;
}
.editor {
  font-size: 16px;
}
::v-deep.el-form-item__content {
  font-size: 30px;
  font-weight: 400;
}
.ciedBrand-text {
  line-height: 40px;
}
.ciedBrand-radio-group {
  margin-left: 0px;
}
.wireway-title {
  font-size: 15px;
  font-weight: 500;
}
.wireway-content {
  display: inline-block;
  width: 60px;
  text-align:right;
}
.el-image__error {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #004ee9;
  vertical-align: middle;
}
.hospital-input {
  width: 150px;
}
.subPart {
  display: block;
  padding-left: 30px;
  width: 288px;
}
.adaptation_content--1 {
  border: 1px solid #EBEEF5;
  display: flex;
  align-items: center;
  width: 450px;
  padding: 16px;
  ::v-deep .el-radio {
    margin-bottom: 0;
  }
}
  .events {
    width: 600px;
    display: flex;
    flex-wrap: wrap;
  }
.lead-atrial {
  padding: 16px;
  width: 510px;
  border: 1px solid #EBEEF5;
}
.lead-right {
  padding: 16px;
  border: 1px solid #EBEEF5;
 width: 510px;
}
.lead-left {
  padding: 16px;
  border: 1px solid #EBEEF5;
  width: 510px;
}
.hasDiseases {
  .el-radio {
    margin-bottom: 0;
  }
}
.diseases-box {
  border: 1px solid #EBEEF5;
  padding: 16px;
  width: 580px;
}
.custom-style {
  margin-top: 8px;
  ::v-deep .el-form-item__label {
    font-size: 14px;
    font-weight: 500;
    color: #606266;
  }
  ::v-deep .el-input__inner, .fix-form__width ,.el-input ,.el-input--mini {
    width:288px;
  }
  ::v-deep .el-form-item__content {
    margin-bottom: 20px;
  }
}
</style>
